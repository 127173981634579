import React from "react";
import ReactDOM from "react-dom";
import Home from "./home/home";
import './index.css';
import './normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <Home />,
  document.getElementById("root")
);
