import React, { Component } from "react";

class Home extends Component {

  render() {
    return (
      <div className="container-fluid">

        <div className="intro-container">

          <div className="intro-background">
            <div class="ripple ripple1"></div>
            <div class="ripple ripple2"></div>
            <div class="ripple ripple3"></div>
          </div>

          <div>
            <div className="social-icons-left">
              <div className="social-icon">
                <a href="https://www.facebook.com/SpeakLife68/" target="_blank" without rel="noopener noreferrer">
                  <img src="img/intro/facebook-128.png" width="100%" alt="Facebook"/>
                </a>
              </div>
              <div className="social-icon">
                <a href="https://www.instagram.com/speaklife68/" target="_blank" without rel="noopener noreferrer">
                  <img src="img/intro/instagram-128.png" width="100%" alt="Instagram"/>
                </a>
              </div>
            </div>
          </div>
      
          <div className="center-intro">
            <img src="img/logo_white.png" width="100%" alt="Logo SpeakLife68"/>
            <br/>
            <span>When was the last time the words you spoke impacted someone’s life?</span>
            <br/>
            <span>Change your life by choosing the right words.</span>
          </div>

          <div className="row">
            <div className="phone-col-center">
              <div className="phone-left hide-on-mobile">
                <img src="img/intro/phone_left.png" width="100%" alt="Phone app left"/>
              </div>
              <div className="phone-center">
                <img src="img/intro/phone_center.png" width="100%" alt="Phone app center"/>
              </div>
              <div className="phone-right hide-on-mobile">
                <img src="img/intro/phone_right.png" width="100%" alt="Phone app right"/>
              </div>
            </div>
          </div>

        </div>

        <div className="app-container">

          <div className="app-col-center">
            <div className="app-store">
              <a href="https://apps.apple.com/nl/app/speaklife68/id1616407319?l=en">
                <img src="img/intro/app.png" width="100%" alt="App store"/>
              </a>
            </div>
            <div className="google-play">
              <a href="https://play.google.com/store/apps/details?id=com.speaklife68.app">
                <img src="img/intro/google.png" width="100%" alt="Google play"/>
              </a>
            </div>
          </div>

        </div>

        <div className="about-container">
          
          <div className="row" >
            <div className="col-sm-6">
              <div  className="about-image">
                <img src="img/logo_icon.png" width="100%" alt="Logo icon SpeakLife68"/>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="about-content-text">
                <h1>About</h1>
                <br/>
                <span>When was the last time the words you spoke impacted someone’s life?</span>
                <br/>
                <span>Do you ever struggle finding the right words?</span>
                <br/>
                <span>Maybe you unintentionally hurt people with your words, or maybe you have been hurt by words people have spoken over you.</span>
                <br/>
                <br/>
                <span>Maybe you struggle finding the right words to tell your loved ones how much they mean to you.</span>
                <br/>
                <br/>
                <span>Maybe you have never even thought about how you speak to yourself and others.</span>
                <br/>
                <br/>
                <span>Well, now is the time!</span>
                <br/>
                <span>It’s time to realize how much power words hold.</span>
                <br/>
                <span>How you can change your life by choosing the right words.</span>
                <br/>
                <br/>
                <span>Come join me in this 68-day challenge to speak life! Download the free app now!</span>
                <br/>
                <br/>
                <span>SpeakLife68 is a non-profit organization whose goal is to spread some positivity in this world by empowering people to use their words to speak life and love. Feel free to share this app with everyone you know! By doing so we can all take part in making this world a better place.</span>
                <br/>
                <br/>
                <span>This challenge is a collection of my experiences and those of friends, family and others that inspire me. It is expressed in either a movie, a story, a song, interviews, or questions and I am so excited to share them with you.</span>
              </div>
            </div>
          </div>

          <div className="row copyright" >
            <div className="col-sm-12">
              <p>
                <span>© 2022 SpeakLife68. All Rights Reserved.</span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <span><a className="copyright-link" href="https://speaklife68.com/privacy-policy.pdf" target="_blank" without rel="noopener noreferrer">Privacy Policy</a></span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <span><a className="copyright-link" href="https://speaklife68.com/terms-of-use.pdf" target="_blank" without rel="noopener noreferrer">Terms of Use</a></span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <span><a className="copyright-link" href="mailto:speakit@speaklife68.com">Contact</a></span>
              </p>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default Home;
